import { motion, useInView } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import coinIcon from '@/assets/images/link-coin.png';
import geckoIcon from '@/assets/images/links-gecko.svg';
import dexToolsIcon from '@/assets/images/links-dextools.svg';
import dexscreenerIcon from '@/assets/images/links-dexscreener.svg';
import uniswapIcon from '@/assets/images/links-uniswap.svg';
import etherscanIcon from '@/assets/images/links-etherscan.svg';
import cmcIcon from '@/assets/images/links-cmc.svg';
import baseIcon from '@/assets/images/links-base.svg';
import { cn } from '@/lib/utils';
import { Check, Copy } from 'lucide-react';
import copy from 'copy-to-clipboard';
import { BrowserView, MobileView } from 'react-device-detect';

interface LinkType {
    key: string;
    icon: string;
    url: string;
    title: string;
}

const lizcoinAddress = '0xAF4144cd943ed5362Fed2BaE6573184659CBe6FF';

const links: LinkType[] = [
    {
        key: 'lizcoin',
        icon: coinIcon,
        url: `https://app.uniswap.org/explore/tokens/ethereum/${lizcoinAddress}`,
        title: 'Buy $LIZ'
    },
    {
        key: 'uniswap',
        icon: uniswapIcon,
        url: `https://app.uniswap.org/add/v2/${lizcoinAddress}/ETH`,
        title: 'Uniswap V2 Pool'
    },
    {
        key: 'dexscreener',
        icon: dexscreenerIcon,
        url: `https://dexscreener.com/ethereum/0xD47B93360EAADBA2678c30F64209a42b9800cEE4`,
        title: 'Dex Screener'
    },
    {
        key: 'dextools',
        icon: dexToolsIcon,
        url: `https://www.dextools.io/app/en/ether/pair-explorer/0xd47b93360eaadba2678c30f64209a42b9800cee4?t=1731316380251`,
        title: 'DexTools'
    },
    {
        key: 'cmc',
        icon: cmcIcon,
        url: `https://coinmarketcap.com/currencies/lizcoin/`,
        title: 'CoinMarketCap'
    },
    {
        key: 'gecko',
        icon: geckoIcon,
        url: `https://www.coingecko.com/en/coins/lizcoin`,
        title: 'CoinGecko'
    },
    {
        key: 'etherscan',
        icon: etherscanIcon,
        url: `https://etherscan.io/token/${lizcoinAddress}`,
        title: 'Etherscan'
    },
    {
        key: 'base',
        icon: baseIcon,
        url: `https://basescan.org/token/${lizcoinAddress}`,
        title: 'Base'
    },
]

const LizcoinLinks = () => {

    const ref = useRef(null);
    const isInView = useInView(ref);

    const [hasAnimated, setHasAnimated] = useState(false);
    const [copied, setCopied] = useState(false);

    const openWindow = (url: string) => {
        window.open(url, "_blank");
    }

    const copyAddress = () => {
        copy(`${lizcoinAddress}`);
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 2000)
    }

    useEffect(() => {
        if (isInView) setHasAnimated(true);
    }, [isInView])

    const Link = ({ link, x } : { x: number, link: LinkType}) => {
        return <motion.a 
            key={`lizcoin_link_${link.key}`} 
            href={link.url}
            target='_blank'
            whileHover={{
                scale: 1.02,
                transition: { duration: 0.15 },
            }}
            className={cn(
                "rounded-[20px] overflow-hidden shadow-cta h-[128px] lg:h-[148px] p-0.5 bg-cta-color-only w-[calc(50%_-_20px)] lg:w-[250px]",
                // x === links.length-1 ? "col-span-full" : "lg:max-w-[250px]"
            )}
            transition={{ duration: 1.5, bounceStiffness: 5, bounceDamping: 5, bounce: 0.4, type: 'spring', delay: x * 0.15 }}
            animate={hasAnimated ? "show" : "hide"}
            variants={{
                show: {
                    opacity: 1,
                    y: 0
                },
                hide: {
                    opacity: 0,
                    y: -80
                }
            }}
        >
            <div className='flex flex-col items-center justify-center gap-y-4 w-full h-full rounded-[20px] cursor-pointer transition-all duration-200 bg-black'>
                <img src={link.icon} className='w-12 h-12 lg:w-[60px] lg:h-[60px]' />
                <span className='text-white font-figtree font-bold text-base lg:text-xl leading-5 lg:leading-6'>{link.title}</span>
            </div>
        </motion.a>
    }

    return (
        <section 
            id="lizcoin-benefits"
            className="relative z-[1] md:z-0 flex flex-col justify-center items-center md:justify-between gap-y-10 lg:gap-y-[60px] pt-[72px] lg:py-8 px-2 lg:px-6 w-full lg:mt-32"
        >
            <div className='flex justify-center mx-auto w-full z-[1] h-full'>
                <div ref={ref} className='flex flex-col w-full justify-center items-center gap-y-6'>
                    <BrowserView renderWithFragment>
                        <div className='relative grid grid-cols-4 gap-y-4 gap-x-[15px] lg:gap-y-6 lg:gap-x-6 items-center justify-center px-[18px] text-center'>
                            {links.map((lnk, x) => (
                                <Link key={`lizcoin_link_${lnk.key}`} x={x} link={lnk} />
                            ))}
                        </div>
                    </BrowserView>
                    <MobileView className='relative flex flex-wrap gap-y-4 gap-x-[15px] w-full items-center justify-center text-center'>
                        {links.map((lnk, x) => (
                            <Link key={`lizcoin_link_${lnk.key}`} x={x} link={lnk} />
                        ))}
                    </MobileView>
                </div>
            </div>
            <div className='flex flex-col gap-y-6'>
                <div className='flex flex-col gap-y-3 text-center'>
                    <span className='font-figtree text-[#C9C8C8] text-base leading-5 lg:text-xl lg:leading-6'>$LIZ Token Contract:</span>
                    <span className='font-figtree text-white font-bold text-base leading-5 lg:text-xl lg:leading-6'>ETHEREUM + BASE</span>
                </div>
                <div>
                    <div onClick={copyAddress} className='flex items-center cursor-pointer rounded-[20px] border-[1px] border-white border-opacity-30 gap-x-5 py-5 px-6'>
                        <span className='font-semibold text-white font-nekstsemibold text-base leading-5 hidden lg:block'>0xAF4144cd943ed5362Fed2BaE6573184659CBe6FF</span>
                        <span className='font-semibold text-white font-nekstsemibold text-base leading-5 block lg:hidden'>0xAF4144cd...3184659CBe6FF</span>
                        {copied ? (
                            <Check className='w-6 h-6' color='white' />
                        ) : (
                            <Copy className='w-6 h-6' color='white' />
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default LizcoinLinks;